/* Main container styling */
.home-container {
  box-sizing: border-box;
  /* background-color: var(--light-shade); */
  background-color: transparent;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centering content vertically */
}

/* Hero section styling */
.hero-section {
  /* background-image: url('../images/hero-background.png'); 
  background-size: cover;
  background-position: center; */
  background-color: transparent;
  color: var(--text-color);
  width: 100%; /* Ensure the hero section spans the full width of the browser */
  /* padding: 100px 20px;  */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
}

/* Adjust the hero text for different screen sizes */
.hero-section h1 {
  font-size: 2.4rem; /* Adjust the font size as needed */
  margin-bottom: 0.5rem;
  text-align: center; /* Center the heading text */
}

.hero-section h1, .hero-section p {
  /* Creating a halo effect around the text for high contrast */
  color: rgb(255, 255, 255);
  text-shadow:
    -1px -1px 25px rgb(0, 0, 0),  
     1px -1px 25px rgb(0, 0, 0),
    -1px 1px 25px rgb(0, 0, 0),
     1px 1px 25px rgb(0, 0, 0),
    -2px -2px 25px rgb(0, 0, 0),
     2px -2px 25px rgb(0, 0, 0),
    -2px 2px 25px rgb(0, 0, 0),
     2px 2px 25px rgb(0, 0, 0),
     -1px -1px 25px rgb(0, 0, 0),  
     1px -1px 25px rgb(0, 0, 0),
    -1px 1px 25px rgb(0, 0, 0),
     1px 1px 25px rgb(0, 0, 0),
    -2px -2px 25px rgb(0, 0, 0),
     2px -2px 25px rgb(0, 0, 0),
    -2px 2px 25px rgb(0, 0, 0),
     2px 2px 25px rgb(0, 0, 0);
}




/* Responsive styles for mobile */
@media (max-width: 600px) {
  .hero-section {
    padding: 30px 10px; /* Smaller padding for mobile */
    max-width: 100%; /* Allows the hero section to expand fully */
  }

  .hero-section h1 {
    font-size: 1.5rem;
  }
}