/* Sports.css */

.sports-container {
  padding: 20px;
  background-color: transparent;
  height: auto;

}

.sports-container h1 {
  text-align: center;
}

.sports-container p {
  max-width: 600px;
  margin: 20px auto;
  /* Centers the paragraph with automatic horizontal margins */
  text-align: center;
}