/* Casino.css */

.casino-container {
  padding: 20px;
  background-color: transparent; /* Updated to use variable */
  height: auto;
}

.casino-container h1 {
  text-align: center;
}

.casino-container p {
  max-width: 600px;
  margin: 20px auto; /* Centers the paragraph with automatic horizontal margins */
  text-align: center;
}
