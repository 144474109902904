/* Card.js */

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: auto;
  }
  
  .card {
    text-decoration: none;    
    border-radius: 4px; /* Rounded corners for the card */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.8); /* Drop shadow similar to the image */
    overflow: hidden; /* Ensures the content fits within the rounded corners */
    transition: box-shadow 0.3s ease, transform 0.2s;
    display: block; /* Makes the link a block element */
    width: 300px; /* Width of the card */
    margin: 20px; /* Space around the card */
    text-align: center;
    border-radius: 10px;  
    background-color: transparent;
  }  
  .card:hover {
    transform: scale(1.1);
  }  
  
  .card-top {
    overflow: hidden;
    color: var(--text-secondary);
    height: 50%;
    background-color: var(--dark-accent);
    margin: 0;
    padding: 0;
    border-radius: 10px 10px 0 0;
  }
  
  .card-bottom {
    overflow: hidden; 
    color: var(--dark-shade);
    height: 50%;
    background-color: var(--text-secondary);
    padding: 0 10px 0 10px;
    border-radius: 0 0 10px 10px;
  }

/* Responsive styles for mobile */
@media (max-width: 600px) {
    .card {
      width: auto; 
    }
  }