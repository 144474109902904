/* Index.css */

* {
  box-sizing: border-box;
}
:root {
  /* Primary color scheme */
  --primary-color: #2cebc6;
  --secondary-color: #1292d8;
  --tertiary-color: #f53e60;
  --quaternary-color: #fd8b62;

  /* Neutral color palette */
  --dark-bg: #121212;
  --dark-accent: #1e1e1e;
  --light-accent: #2c2c2c;
  --text-color: #ffffff;
  --text-secondary: #aaaaaa;
  --border-color: #333333;

  /* Additional shades (if needed) */
  --dark-shade: #0a0a0a;
  --light-shade: #f5f5f5;
  --hover-color: rgba(255, 255, 255, 0.1); /* White with opacity for hover effects */  
}

body {
  background-image: url('../images/bg-dalle-gold-gambling-motif-DARKENED.png');
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1120px;
  margin: auto;
}