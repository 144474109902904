/* Header styles */
header {
  position: relative;
  z-index: 2;
  /* background-color: var(--dark-accent); */
  background-color: transparent;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text); /* Changed from white to dark text color */
  max-width: 1120px;
}

.nav-toggle {
  display: none;
  padding: 10px 20px;
  /* Other styles */
}

.logo img {
  max-height: 60px; /* Adjust based on your header's size */
  width: auto; /* Maintains the aspect ratio */
  padding-top: 5px;
  padding-left: 10px;
  margin-left: 0;
}

header nav.visible {
  background-color: transparent;
}

.logo img:hover {
  transition: transform 0.2s ease;
  transform: scale(1.1);
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin-left: 20px;
}

nav a {
  text-decoration: none;
  color: var(--color-text); /* Changed from white to dark text color */
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

nav a:hover {
  color: var(--secondary-color);
}


/* Footer Styling */
.footer {
  display: flex;
  justify-content: center;
  gap: 100px;
  text-align: center;
  align-items: flex-start; /* Aligns columns to the top */
  flex-wrap: wrap; /* Allows columns to wrap on smaller screens */
  padding: 20px;
  background-color: transparent;
  color: var(--text-color);
  max-width: 1120px;
}

.footer-column-left .footer-column-right {
  flex: 1; /* Each column will take equal space */
  padding: 0px 30px; /* Maintain padding on left and right */
  width: 500px;
}

.footer h3 {
  margin-bottom: 10px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li a {
  display: block;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s;
  margin: 8px 0; /* Adjusted for spacing between links */
}

.footer ul li a:hover {
  color: var(--secondary-color);
}

@media (max-width: 660px) {
  header {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-left: 10px;
  }

  .logo img {
    order: -1;
    align-self: flex-start;
    padding-left: 10px;
    max-height: 60px;
    padding-top: 5px;
    margin-left: 0;
    width: auto;
  } 

  nav {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease;
    background-color: var(--dark-accent);
  }

  .nav-toggle {
    display: block;
    position: absolute;
    right: 10px;
    z-index: 5;
    background: none;
    border: none;
    color: var(--color-text);
    font-size: 1.5em;
    cursor: pointer;
    padding-top: 15px;
  }

  nav.visible {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 20px);
    padding-top: 20px;
    top: 60px;
    left: 0;
    background-color: transparent;
  }

  nav ul {
    flex-direction: column;
    width: 100%;
  }

  nav li {
    text-align: center;
    padding: 0;
    margin: 0;
  }

  nav li:first-child {
    border-top: none;
  }

  nav a {
    display: block;
    padding: 10px;
    transition: background-color 0.3s ease;
  }

  nav a:hover {
    background-color: var(--color-primary); /* Subtle background color on hover */
    color: var(--color-white); /* White text on hover for contrast */
  }

  .footer {
    padding: 0;
  }
}