/* ICMCalculator.css */

.icm-calculator-container {
    background-color: var(--dark-accent);
    padding: 20px;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    min-width: 340px;
}

.icm-calculator-container h1 {
    color: var(--primary-color);
    text-align: center;
}

.icm-calculator-container p {
    color: var(--text-color);
    text-align: center;
    margin-bottom: 20px;
}

/* Player Input Styling */
.structure-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 10px;
    margin-bottom: 20px;
}

.structure-container label {
    margin-right: 10px;
    font-size: 1.1rem;
}

.players-container,
.payouts-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.payouts-container label {
    width: 180px;

}

.places-paid {
    margin-bottom: 8px;
}

.places-paid label {
    width: 120px;
}

.number-of-players,
.places-paid,
.player-chips,
.payout-amount {
    display: flex;
    align-items: center;
}

.player-chips label {
    width: auto;
    /* Let the width be determined by content up to a maximum width */
    min-width: 142px;
    max-width: 200px;
}

.payout-amount label {
    width: auto;
    /* Let the width be determined by content up to a maximum width */
    min-width: 120px;
    max-width: 200px;
    /* Adjust as needed */
}


.number-of-players {
    margin-bottom: 8px;
}

.number-of-players label {
    color: var(--text-color);
    width: 142px;
}

.info-title {
    padding: 0;
    margin: 5px 0 -10px 0;
}

.info-title h2 {
    color: var(--tertiary-color);
    font-size: 1.3rem;
}


.structure-container input[type='number'],
.structure-container select {
    padding: 5px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--dark-bg);
    color: var(--text-color);
    font-size: 1.1rem;
    box-sizing: border-box;
    /* Adjust width to be responsive instead of a fixed min-width */
    width: calc(100% - 20px);
}



.structure-container select {
    width: 60px;
}

.structure-container button {
    background-color: var(--secondary-color);
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    color: var(--text-color);
    cursor: pointer;
    margin: 2px;
}

.icm-calculate-reset-buttons button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    color: var(--text-color);
    background-color: var(--secondary-color);
    cursor: pointer;
    font-family: inherit;
    font-weight: bold;
    font-size: 1rem;
}

.icm-calculate-reset-buttons .icm-reset-button {
    background-color: var(--tertiary-color);
}

/* ICM Results Styling */
.icm-results {
    background-color: var(--light-accent);
    padding: 5px 10px 8px;
    /* Increased padding for better spacing */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 20px auto;
    /* Increased margin for better spacing */
}

.icm-results h2 {
    color: var(--primary-color);
    margin-bottom: 15px;
    /* Increased margin for better spacing */
    font-size: 1.3rem;
}

.icm-payout .payout-line {
    display: flex;
    justify-content: flex-start;
    /* Align to the start to prevent stretching */
    align-items: center;
    margin: 8px 0;
    /* Top and bottom margin for each payout line */
}

.icm-payout .player-label {
    margin-right: 15px;
    /* Add margin to separate from the amount */
    font-size: 1.1rem;
    min-width: 80px;
}

.icm-payout .payout-amount {
    text-align: left;
    font-size: 1.1rem;
}

/* CSS for Calculation Progress Text */
.calculation-progress {
    color: var(--primary-color);
    text-align: center;
    padding: 10px;
    font-size: 1.2rem;
    margin-top: 20px;
}

/* CSS for a Visual Progress Bar */
.calculation-progress-bar {
    background-color: var(--light-accent);
    border-radius: 4px;
    overflow: hidden;
    /* Ensures the inner bar stays within the border radius */
    position: relative;
    height: 20px;
    width: 100%;
    margin: 10px 0;
}

.calculation-progress-bar-inner {
    background-color: var(--primary-color);
    height: 100%;
    transition: width 0.5s ease;
    /* Smooth transition for the width */
}

/* Add this to your existing CSS */
.icm-calculate-reset-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

.icm-calculate-reset-buttons button {
    flex-grow: 1;
}

.icm-calculate-button:hover {
    color: var(--primary-color);
    background-color: var(--dark-shade);
}

.icm-reset-button:hover {
    color: var(--dark-shade);
    background-color: var(--text-secondary);
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid var(--primary-color);
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Media Query for Desktop View */
@media (min-width: 780px) {
    .structure-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    .player-chips label {
        width: auto;
        /* Let the width be determined by content up to a maximum width */
        min-width: 142px;
        max-width: 200px;
        /* Adjust as needed */
    }

    .payout-amount label {
        width: auto;
        /* Let the width be determined by content up to a maximum width */
        min-width: 120px;
        max-width: 200px;
        /* Adjust as needed */
    }

    .structure-container select {
        width: 60px;
        /* Full width of their container */
    }

    .icm-calculate-reset-buttons {
        display: flex;
        justify-content: space-between;
        /* Adjust buttons to be spaced out across the container */
        gap: 20px;
    }

    .icm-results {
        grid-column: 1 / -1;
        /* Full width of the grid */
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: var(--dark-bg);
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: var(--text-color);
}

.modal-content h2 {
    color: var(--primary-color);
    margin-bottom: 15px;
    text-align: center;
}

.modal-content textarea {
    background-color: var(--light-accent);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
}

.modal-content button {
    background-color: var(--secondary-color);
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    color: var(--text-color);
    cursor: pointer;
    margin: 5px;
    width: 100px;
}

.modal-content button:hover {
    background-color: var(--primary-color);
}

.icm-payout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copy-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #f0f0f0;
    transition: background-color 0.2s;
}

.copy-button:hover {
    background-color: #e0e0e0;
}

.copy-button svg {
    width: 18px;
    height: 18px;
}

.toast-notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    animation: fadeInOut 3s ease;
}

.toast-content {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding: 12px 20px;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 300px;
}

.toast-content svg {
    color: var(--primary-color);
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    10% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}